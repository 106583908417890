import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PlansStoreModule } from '../plans-store';
import { DashboardPianiStoreModule } from '@store/dashboard-piani-store';
import { GridColumnsStoreModule } from '@store/grid-columns-store';
import { ContrattiStoreModule } from '@store/contratti-store';
import { RolesStoreModule } from '@store/roles-store';
import { GridFiltersStoreModule } from '@store/grid-filters-store';
import { CommonsStoreModule } from '@store/commons-store';


@NgModule( {
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot( {} ),
        EffectsModule.forRoot(),
        StoreDevtoolsModule.instrument( { maxAge: 24 , connectInZone: true} ),
        PlansStoreModule,
        DashboardPianiStoreModule,
        GridColumnsStoreModule,
        ContrattiStoreModule,
        RolesStoreModule,
        GridFiltersStoreModule,
        CommonsStoreModule,
    ],
} )
export class RootStoreModule {
}
