import { FilesContainerService } from '@shared/services/files-container.service';
import { NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { CoreModule } from '@core/core.module';
import { ThemeModule } from '@theme/theme.module';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from '@shared';
import { AppComponent } from './app.component';

import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { httpInterceptorProviders } from '@core/interceptors';
import { appInitializerProviders } from '@core/initializers';
import { FormlyConfigModule } from './formly-config.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializerEnvironment } from '@env/environment';
import { RootStoreModule } from '@store/root-store/root-store.module';
import {
    AUTHZ_URL,
    BASE_URL,
    BASELINES_URL,
    CACHE_HANDLER_URL,
    CONTRATTI_URL,
    DUST_URL,
    FIBRA_URL,
    FORECAST_URL,
    GRID_COLUMNS_URL,
    LISTA_SITI_URL,
    LOGGER_URL,
    NEW_SITE_URL,
    NOTIFICATIONS_URL,
    OFFICES_URL,
    PIANI_URL,
    REPORTING_URL,
    TOOL_TX_URL,
    TRACKING_URL,
    TRATTE_URL
} from '@shared/models/urls-constants';
import { BlockUIModule } from 'ng-block-ui';

// Required for AOT compilation
export const TranslateHttpLoaderFactory = ( http: HttpClient ) => new TranslateHttpLoader( http, './assets/i18n/', '.json' );

// let base = document.getElementsByTagName('base')[0].href;
// base = 'https://localhost:5002/';
export const getBaseUrl = () => initializerEnvironment.resourceServer;
export const getOfficesUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-offices-api/`;
export const getContrattiUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-contratti-api/`;
export const getPianiUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-piani-api/`;
export const getTratteUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-tratte-api/`;
export const getBaselinesUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-baselines-api/`;
export const getFibraUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-fibra-api/`;
export const getForecastUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-forecast-api/`;
export const getAuthzUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-authz/`;
export const getNotificationsUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-notifications-api/`;
export const getDustUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-dust-api/`;
export const getListaSitiUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-lista-siti-api/`;
export const getGridColumnsUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-gridcolumns-api/`;
export const getReportingUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-reportsmenu-api/`;
export const getTrackingUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-tracking-api/`;
export const getNewSiteUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-requestnewoffice-api/`;
export const getCacheHandlerUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-cachehandler-api/`;
export const getLoggingUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-frontendlog-api/`;
export const getToolTxUrl = () => `${ initializerEnvironment.resourceServer }wonder-src-tooltx-api/`;

@NgModule( { declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        CoreModule,
        ThemeModule,
        RoutesModule,
        SharedModule,
        FormlyConfigModule.forRoot(),
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateHttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
        RootStoreModule,
        BlockUIModule.forRoot()], providers: [
        httpInterceptorProviders,
        appInitializerProviders,
        { provide: BASE_URL, useFactory: getBaseUrl },
        { provide: OFFICES_URL, useFactory: getOfficesUrl },
        { provide: CONTRATTI_URL, useFactory: getContrattiUrl },
        { provide: PIANI_URL, useFactory: getPianiUrl },
        { provide: TRATTE_URL, useFactory: getTratteUrl },
        { provide: BASELINES_URL, useFactory: getBaselinesUrl },
        { provide: FIBRA_URL, useFactory: getFibraUrl },
        { provide: FORECAST_URL, useFactory: getForecastUrl },
        { provide: AUTHZ_URL, useFactory: getAuthzUrl },
        { provide: NOTIFICATIONS_URL, useFactory: getNotificationsUrl },
        { provide: DUST_URL, useFactory: getDustUrl },
        { provide: LISTA_SITI_URL, useFactory: getListaSitiUrl },
        { provide: GRID_COLUMNS_URL, useFactory: getGridColumnsUrl },
        { provide: REPORTING_URL, useFactory: getReportingUrl },
        { provide: 'AVATAR_FILE_CONTAINER', useClass: FilesContainerService },
        { provide: TRACKING_URL, useFactory: getTrackingUrl },
        { provide: NEW_SITE_URL, useFactory: getNewSiteUrl },
        { provide: CACHE_HANDLER_URL, useFactory: getCacheHandlerUrl },
        { provide: LOGGER_URL, useFactory: getLoggingUrl },
        { provide: TOOL_TX_URL, useFactory: getToolTxUrl },
        provideHttpClient(withInterceptorsFromDi()),
    ] } )
export class AppModule {
}

